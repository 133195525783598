import 'vanilla-cookieconsent/dist/cookieconsent.css'
import './wccc.css'
import * as CookieConsent from 'vanilla-cookieconsent'

async function getTrackers(domain) {
  return (
    await (
      await fetch('https://privacy-cms.webcloud.cloud/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: /* graphql */ `
            {
              policies_nolang(filter: {translations: {Domain: {_eq: "${domain}"}}}) {
                id
                services(filter: {ServicesId: {trackers: {name: {_nnull: true}}}}) {
                  ServicesId {
                    Name
                    trackers(sort: ["name"]) {
                      tracker_typology
                      name
                      pattern
                      is_pattern_regex
                      storage_duration
                      cookie_typology
                    }
                  }
                }
              }
            }
          `,
        }),
      })
    ).json()
  ).data.policies_nolang[0]
}

function translateDuration(value, lang) {
  return value
    .replace('anni', {it: 'anni', en: 'years', de: 'Jahre'}[lang])
    .replace('mesi', {it: 'mesi', en: 'months', de: 'Monate'}[lang])
    .replace('giorni', {it: 'giorni', en: 'days', de: 'Tage'}[lang])
    .replace('ore', {it: 'ore', en: 'hours', de: 'Stunden'}[lang])
    .replace('minuti', {it: 'minuti', en: 'minutes', de: 'Minuten'}[lang])
    .replace('secondi', {it: 'secondi', en: 'seconds', de: 'Sekunden'}[lang])
    .replace('anno', {it: 'anno', en: 'year', de: 'Jahr'}[lang])
    .replace('mese', {it: 'mese', en: 'month', de: 'Monat'}[lang])
    .replace('giorno', {it: 'giorno', en: 'day', de: 'Tag'}[lang])
    .replace('ora', {it: 'ora', en: 'hour', de: 'Stunde'}[lang])
    .replace('minuto', {it: 'minuto', en: 'minute', de: 'Minute'}[lang])
    .replace('secondo', {it: 'secondo', en: 'second', de: 'Sekunde'}[lang])
    .replace('Sessione', {it: 'Sessione', en: 'Session', de: 'Session'}[lang])
}

function includeCookieTable(trackers, typology, lang) {
  return {
    headers: {
      name: {it: 'Nome', en: 'Name', de: 'Name'}[lang],
      duration: {it: 'Durata archiviazione', en: 'Storage duration', de: 'Speicherdauer'}[lang],
      service: {it: 'Servizio', en: 'Service', de: 'Dienst'}[lang],
    },
    body: trackers.services
      .flatMap(service =>
        service.ServicesId.trackers.map(tracker => ({...tracker, service: service.ServicesId.Name}))
      )
      .filter(tracker => tracker.cookie_typology === typology)
      .map(tracker => ({
        name: tracker.name,
        duration: translateDuration(tracker.storage_duration, lang),
        service: tracker.service,
      })),
  }
}

function includeAutoClearCookies(trackers, typology) {
  return trackers.services
    .flatMap(service => service.ServicesId.trackers)
    .filter(
      tracker => tracker.tracker_typology === 'cookie' && tracker.cookie_typology === typology
    )
    .map(tracker => ({
      name: tracker.is_pattern_regex ? new RegExp(tracker.pattern) : tracker.pattern,
    }))
}

async function logConsent(policyId, tcString) {
  const cookie = CookieConsent.getCookie()
  const preferences = CookieConsent.getUserPreferences()

  await fetch('https://privacy-cms.webcloud.cloud/items/consents', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: cookie.consentId,
      accept_type: preferences.acceptType,
      accepted_categories: preferences.acceptedCategories,
      rejected_categories: preferences.rejectedCategories,
      url: window.location.href,
      tc_string: tcString,
      PoliciesId: policyId,
    }),
  })
}

//! has to fire within 1500 ms from gtag('consent', 'default', ...
function updateGtagConsent(cookieCategories) {
  if (typeof gtag !== 'function') {
    return
  }

  let consents = {}

  if (cookieCategories.includes('analytics')) {
    consents.analytics_storage = CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied'
  }

  if (cookieCategories.includes('functionality')) {
    consents.functionality_storage = CookieConsent.acceptedCategory('functionality')
      ? 'granted'
      : 'denied'
  }

  if (cookieCategories.includes('marketing')) {
    const consent = CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied'

    consents = {
      ...consents,
      ad_personalization: consent,
      ad_storage: consent,
      ad_user_data: consent,
      personalization_storage: consent,
    }
  }

  if (Object.keys(consents).length) {
    gtag('consent', 'update', consents)
  }
}

function onFirstConsentOrChange(policyId, cookieCategories, tcString) {
  logConsent(policyId, tcString)
}

function onConsentOrChangeInternal(privacyPolicyUrl, cookieCategories) {
  updateGtagConsent(cookieCategories)

  if (CookieConsent.getUserPreferences().acceptType !== 'all') {
    const lang = getLanguageIndex()

    document.querySelectorAll('[data-wc-cc-bc]').forEach(el => {
      el.outerHTML =
        `<div data-wc-cc-bc-dg style="${
          window.getComputedStyle(el.parentElement).getPropertyValue('position') !== 'static'
            ? 'position:absolute;top:0;left:0;right:0;bottom:0;'
            : ''
        }background-color:#d1d1d1;display:flex;align-items:center;justify-content:center;overflow:hidden;flex-flow:column nowrap;padding:30px;"><div style="max-width:600px;display:flex;flex-flow:column nowrap;gap:30px"><div style="font-weight:700;font-size:20px;font-family:sans-serif;line-height:1.1;color:#555;text-align:center">${
          ['ATTENZIONE', 'ATTENTION', 'AUFMERKSAMKEIT'][lang]
        }<div></div>${
          [
            'Questo contenuto è bloccato perché non hai accettato tutti i cookie',
            'This content is blocked because you have not accepted all cookies',
            'Dieser Inhalt ist blockiert, da Sie nicht alle Cookies akzeptiert haben',
          ][lang]
        }.</div><div style="font-family:sans-serif;line-height:1.3;font-size:13px;color:#666">${
          [
            'Il nostro sito prevede funzionalità aggiuntive non disponibili se non previa accettazione dei cookie. Puoi prendere visione della nostra',
            'Our site provides additional features that are not available unless cookies are accepted. You can view ours',
            'Unsere Website bietet zusätzliche Funktionen, die nur verfügbar sind, wenn Cookies akzeptiert werden. Sie können sich unsere ansehen',
          ][lang]
        } <a href="${privacyPolicyUrl}" style="display:inline;cursor:pointer;text-decoration:underline;font-family:sans-serif;color:inherit;font-size:inherit" target="_blank">${
          ['informativa sulla privacy', 'Privacy Policy', 'Datenschutzrichtlinie'][lang]
        }</a> ${
          [
            'e, se lo ritieni opportuno,',
            'and, if you deem it appropriate,',
            'und wenn Sie es für angemessen halten,',
          ][lang]
        } <span onclick="wcccShowPreferences()" style="display:inline;cursor:pointer;text-decoration:underline;font-family:sans-serif;font-weight:700;color:#0051ff;font-size:inherit">${
          [
            'cliccare qui per modificare le preferenze cookie',
            'click here to change your cookie preferences',
            'Klicken Sie hier, um Ihre Cookie-Einstellungen zu ändern',
          ][lang]
        }</span>. ${
          [
            'Se vuoi usufruire di tutte le funzionalità di questo sito, ti consigliamo di accettare tutti i cookie',
            'If you want to take advantage of all the features of this site, we recommend that you accept all cookies',
            'Wenn Sie alle Funktionen dieser Website nutzen möchten, empfehlen wir Ihnen, alle Cookies zu akzeptieren',
          ][lang]
        }.</div></div></div>` + el.outerHTML
    })
  } else {
    document.querySelectorAll('[data-wc-cc-bc-dg]').forEach(el => {
      el.remove()
    })
    window.dispatchEvent(new Event('wc-cc-bc-show'))
  }
}

function getLanguageIndex() {
  switch (document.documentElement.lang) {
    case 'it':
    case 'it-IT':
      return 0
    case 'en':
    case 'en-GB':
    case 'en-US':
      return 1
    case 'de':
    case 'de-DE':
      return 2
    default:
      return 1
  }
}

function getTranslations(privacyPolicyUrl, trackers) {
  return {
    it: {
      consentModal: {
        title: 'Informativa',
        description: `Usiamo i cookie e altre tecnologie simili per far funzionare il nostro sito. Con il tuo permesso, vorremmo usare i cookie anche per altre cose, come te lo spieghiamo nella nostra <a href="${privacyPolicyUrl}" target="_blank">informativa sulla privacy</a>. Se non sei d'accordo, puoi cliccare su 'rifiuta' per continuare senza i cookie aggiuntivi. In tal caso potresti non essere in grado di utilizzare tutte le funzionalità del nostro sito.`,
        footer: `<a href="${privacyPolicyUrl}" target="_blank">Informativa sulla privacy</a><a onclick="wcccAcceptNecessary()" style="font-weight:normal;font-size:12px;cursor:pointer;margin-left:auto;align-self:center;">Rifiuta</a>`,
        acceptAllBtn: 'Accetta',
        showPreferencesBtn: 'Gestisci preferenze',
      },
      preferencesModal: {
        title: 'Preferenze cookie',
        acceptAllBtn: 'Accetta tutti',
        acceptNecessaryBtn: 'Rifiuta tutti',
        savePreferencesBtn: 'Conferma preferenze',
        closeIconLabel: 'Chiudi la finestra',
        sections: [
          {
            description:
              'Qui hai la possibilità di scegliere quali tipi di cookie accettare o rifiutare. Tieni presente che se decidi di rifiutare alcuni cookie, potresti non essere in grado di utilizzare tutte le funzionalità del nostro sito.',
          },
          {
            title: 'Cookie tecnici <span class="pm__badge">Sempre attivati</span>',
            description:
              'Essenziali per la navigazione del sito. Senza questi cookie, assolutamente necessari, non saremmo in grado di fornire certi servizi o funzioni e la navigazione sul sito risulterebbe poco agevole e più difficile di come dovrebbe essere',
            linkedCategory: 'necessary',
            cookieTable: includeCookieTable(trackers, 'necessary', 'it'),
          },
          {
            title: 'Cookie di analisi',
            description:
              'Migliorano il funzionamento del sito raccogliendo informazioni sul suo utilizzo, mostrando ad esempio quali sono le pagine più visitate di frequente; ci agevolano anche nell’individuazione di eventuali difficoltà riscontrate durante la navigazione del sito',
            linkedCategory: 'analytics',
            cookieTable: includeCookieTable(trackers, 'analytics', 'it'),
          },
          {
            title: 'Cookie di funzionalità',
            description:
              'Consentono di ricordare le scelte che compi sul sito per fornirti un’esperienza più personalizzata, ad esempio dandoti la possibilità di scegliere cosa visualizzare in una certa pagina o mostrandoti promozioni a cui non hai già partecipato',
            linkedCategory: 'functionality',
            cookieTable: includeCookieTable(trackers, 'functionality', 'it'),
          },
          {
            title: 'Cookie di profilazione',
            description:
              'Usati per mostrarti pubblicità che riteniamo potresti trovare interessanti e misurare l’efficacia delle nostre campagne pubblicitarie. Questi cookie ricordano cosa hai visto sul sito. Possiamo condividere queste informazioni con altri soggetti, ad esempio, gli inserzionisti pubblicitari',
            linkedCategory: 'marketing',
            cookieTable: includeCookieTable(trackers, 'marketing', 'it'),
          },
          {
            description: `<a href="${privacyPolicyUrl}" target="_blank">Informativa sulla privacy</a>`,
          },
        ],
      },
    },
    en: {
      consentModal: {
        title: 'Information',
        description: `We use cookies and similar technologies to operate our site. With your permission, we would also like to use cookies for other purposes, as explained in our <a href="${privacyPolicyUrl}" target="_blank">privacy policy</a>. If you do not agree, you can click 'reject' to continue without additional cookies. In this case, you may not be able to use all the features of our site.`,
        footer: `<a href="${privacyPolicyUrl}" target="_blank">Privacy Policy</a><a onclick="wcccAcceptNecessary()" style="font-weight:normal;font-size:12px;cursor:pointer;margin-left:auto;align-self:center;">Reject</a>`,
        acceptAllBtn: 'Accept',
        showPreferencesBtn: 'Manage preferences',
      },
      preferencesModal: {
        title: 'Cookie Preferences',
        acceptAllBtn: 'Accept all',
        acceptNecessaryBtn: 'Reject all',
        savePreferencesBtn: 'Save preferences',
        closeIconLabel: 'Close window',
        sections: [
          {
            description:
              'Here you can choose which types of cookies to accept or reject. Please note that if you decide to reject some cookies, you may not be able to use all the features of our site.',
          },
          {
            title: 'Technical Cookies <span class="pm__badge">Always active</span>',
            description:
              'Essential for site navigation. Without these absolutely necessary cookies, we would not be able to provide certain services or functions, and navigation on the site would be less smooth and more difficult than it should be',
            linkedCategory: 'necessary',
            cookieTable: includeCookieTable(trackers, 'necessary', 'en'),
          },
          {
            title: 'Analytics Cookies',
            description:
              'Improve the functioning of the site by collecting information on its use, such as showing which pages are most frequently visited; they also help us identify any difficulties encountered during site navigation',
            linkedCategory: 'analytics',
            cookieTable: includeCookieTable(trackers, 'analytics', 'en'),
          },
          {
            title: 'Functionality Cookies',
            description:
              'Allow remembering the choices you make on the site to provide you with a more personalized experience, such as giving you the option to choose what to display on a certain page or showing you promotions you have not already participated in',
            linkedCategory: 'functionality',
            cookieTable: includeCookieTable(trackers, 'functionality', 'en'),
          },
          {
            title: 'Profiling Cookies',
            description:
              'Used to show you advertisements that we think you might find interesting and to measure the effectiveness of our advertising campaigns. These cookies remember what you have seen on the site. We may share this information with other parties, such as advertisers',
            linkedCategory: 'marketing',
            cookieTable: includeCookieTable(trackers, 'marketing', 'en'),
          },
          {
            description: `<a href="${privacyPolicyUrl}" target="_blank">Privacy Policy</a>`,
          },
        ],
      },
    },
    de: {
      consentModal: {
        title: 'Informationen',
        description: `Wir verwenden Cookies und ähnliche Technologien, um unsere Website zu betreiben. Mit Ihrer Erlaubnis möchten wir Cookies auch für andere Zwecke verwenden, wie wir es in unserer <a href="${privacyPolicyUrl}" target="_blank">Datenschutzerklärung</a> erklären. Wenn Sie nicht einverstanden sind, können Sie auf 'ablehnen' klicken, um ohne zusätzliche Cookies fortzufahren. In diesem Fall können Sie möglicherweise nicht alle Funktionen unserer Website nutzen.`,
        footer: `<a href="${privacyPolicyUrl}" target="_blank">Datenschutzerklärung</a><a onclick="wcccAcceptNecessary()" style="font-weight:normal;font-size:12px;cursor:pointer;margin-left:auto;align-self:center;">Ablehnen</a>`,
        acceptAllBtn: 'Akzeptieren',
        showPreferencesBtn: 'Einstellungen verwalten',
      },
      preferencesModal: {
        title: 'Cookie-Einstellungen',
        acceptAllBtn: 'Alle akzeptieren',
        acceptNecessaryBtn: 'Alle ablehnen',
        savePreferencesBtn: 'Einstellungen speichern',
        closeIconLabel: 'Fenster schließen',
        sections: [
          {
            description:
              'Hier können Sie auswählen, welche Arten von Cookies Sie akzeptieren oder ablehnen möchten. Bitte beachten Sie, dass Sie möglicherweise nicht alle Funktionen unserer Website nutzen können, wenn Sie einige Cookies ablehnen.',
          },
          {
            title: 'Technische Cookies <span class="pm__badge">Immer aktiv</span>',
            description:
              'Unverzichtbar für die Navigation auf der Website. Ohne diese absolut notwendigen Cookies könnten wir bestimmte Dienste oder Funktionen nicht bereitstellen und die Navigation auf der Website wäre weniger reibungslos und schwieriger, als sie sein sollte',
            linkedCategory: 'necessary',
            cookieTable: includeCookieTable(trackers, 'necessary', 'de'),
          },
          {
            title: 'Analyse-Cookies',
            description:
              'Verbessern die Funktionsweise der Website, indem sie Informationen über deren Nutzung sammeln, z.B. welche Seiten am häufigsten besucht werden; sie helfen uns auch, eventuelle Schwierigkeiten bei der Navigation auf der Website zu erkennen',
            linkedCategory: 'analytics',
            cookieTable: includeCookieTable(trackers, 'analytics', 'de'),
          },
          {
            title: 'Funktions-Cookies',
            description:
              'Ermöglichen das Speichern Ihrer Auswahl auf der Website, um Ihnen eine personalisierte Erfahrung zu bieten, z.B. indem Sie auswählen können, was auf einer bestimmten Seite angezeigt wird oder Ihnen Promotions anzeigen, an denen Sie noch nicht teilgenommen haben',
            linkedCategory: 'functionality',
            cookieTable: includeCookieTable(trackers, 'functionality', 'de'),
          },
          {
            title: 'Profiling-Cookies',
            description:
              'Werden verwendet, um Ihnen Werbung zu zeigen, die wir für interessant halten, und um die Effektivität unserer Werbekampagnen zu messen. Diese Cookies speichern, was Sie auf der Website gesehen haben. Wir können diese Informationen mit anderen Parteien, z.B. Werbetreibenden, teilen',
            linkedCategory: 'marketing',
            cookieTable: includeCookieTable(trackers, 'marketing', 'de'),
          },
          {
            description: `<a href="${privacyPolicyUrl}" target="_blank">Datenschutzerklärung</a>`,
          },
        ],
      },
    },
  }
}

function getCookieDomain(domain) {
  const hostnameParts = domain.split('.').reverse()

  if (hostnameParts.length < 3) {
    return domain
  }

  const [tld, secondLevel, thirdLevel] = hostnameParts
  let result = `${secondLevel}.${tld}`

  //! we only support co.uk
  if (result === 'co.uk') {
    return `${thirdLevel}.${result}`
  }

  return result
}

window.wcccShowPreferences = function () {
  if (window.wcccEnableGooglefc) {
    window?.googlefc?.showRevocationMessage && window.googlefc.showRevocationMessage()
  } else {
    CookieConsent.showPreferences()
  }
}

window.wcccAcceptNecessary = function () {
  CookieConsent.acceptCategory([])
  CookieConsent.hide()
}

window.wccc = async function ({
  privacyPolicyDomain,
  privacyPolicyUrl,
  onConsentOrChange,
  enableGooglefc,
}) {
  let tcString

  if (enableGooglefc) {
    // Make sure that the properties exist on the window.
    window.googlefc = window.googlefc || {}
    window.googlefc.callbackQueue = window.googlefc.callbackQueue || []

    // Queue the callback using the CONSENT_API_READY key on the callbackQueue.
    window.googlefc.callbackQueue.push({
      CONSENT_DATA_READY: () =>
        window.__tcfapi('addEventListener', 2.2, (data, success) => {
          if (
            data.purpose?.consents &&
            (data?.eventStatus === 'useractioncomplete' || !CookieConsent.validConsent())
          ) {
            // true = user consented to all purposes
            const hasFullConsent = Object.entries(data.purpose.consents).every(
              item => item[1] === true || parseInt(item[0], 10) > 10
            )

            tcString = data.tcString

            // [] = reject all (accept only categories marked as readOnly/necessary)
            CookieConsent.acceptCategory(hasFullConsent ? 'all' : [])
          }
        }),
    })
  }

  const cookieDomain = getCookieDomain(window.location.hostname)
  // clear old cookies
  CookieConsent.eraseCookies('cc_cookie', '/', cookieDomain)
  if (cookieDomain !== window.location.hostname) {
    CookieConsent.eraseCookies('cc_cookie', '/', window.location.hostname)
  }

  const trackers = await getTrackers(privacyPolicyDomain)
  const policyId = trackers?.id

  if (!policyId) {
    console.error('No privacy policy found for this domain')
    return
  }

  window.wcccEnableGooglefc = enableGooglefc

  const translations = getTranslations(privacyPolicyUrl, trackers)

  CookieConsent.run({
    autoShow: !enableGooglefc,

    revision: 3,

    cookie: {
      name: 'wccc_cookie',
      domain: cookieDomain,
    },

    // This event is triggered when one of the modals is visible.
    onModalShow: ({modalName}) => {
      try {
        // in case of cookie consent accepting plugins like i don't care about cookies
        // (otherwise the body cannot scroll)
        if (window.getComputedStyle(document.querySelector('#cc-main')).display === 'none') {
          // document.documentElement.classList.remove('show--consent')
          window.wcccAcceptNecessary()
        }
      } catch (e) {}
    },

    // This event is triggered only the very first time that the user expresses their choice of consent (accept/reject).
    onFirstConsent({cookie}) {
      onFirstConsentOrChange(policyId, cookie.categories, tcString)
    },

    // This event is triggered the very first time the user expresses their choice of consent — just like onFirstConsent — but also on every subsequent page load.
    onConsent: ({cookie}) => {
      onConsentOrChangeInternal(privacyPolicyUrl, cookie.categories)
      onConsentOrChange && onConsentOrChange(cookie.categories)
    },

    // This event is triggered when the user modifies their preferences and only if consent has already been provided.
    onChange({changedCategories}) {
      onFirstConsentOrChange(policyId, changedCategories, tcString)
      onConsentOrChangeInternal(privacyPolicyUrl, changedCategories)
      onConsentOrChange && onConsentOrChange(changedCategories)
    },

    disablePageInteraction: true,

    guiOptions: {
      consentModal: {
        layout: 'box',
        position: 'middle center',
        equalWeightButtons: false,
        flipButtons: false,
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: false,
      },
    },

    categories: {
      necessary: {
        readOnly: true,
        autoClear: {
          cookies: includeAutoClearCookies(trackers, 'necessary'),
          reloadPage: true,
        },
      },
      functionality: {
        autoClear: {
          cookies: includeAutoClearCookies(trackers, 'functionality'),
          reloadPage: true,
        },
      },
      analytics: {
        autoClear: {
          cookies: includeAutoClearCookies(trackers, 'analytics'),
          reloadPage: true,
        },
      },
      marketing: {
        autoClear: {
          cookies: includeAutoClearCookies(trackers, 'marketing'),
          reloadPage: true,
        },
      },
    },

    language: {
      default: 'en',
      autoDetect: 'document',
      translations: {
        'it': translations['it'],
        'it-IT': translations['it'],

        'en': translations['en'],
        'en-GB': translations['en'],
        'en-US': translations['en'],

        'de': translations['de'],
        'de-DE': translations['de'],
      },
    },
  })
}
